import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../components/helpers/Axios";
export const saveProfile=createAsyncThunk("profile/save",async({values,onNext,lastStep,setIsDialogOpen},{rejectWithValue})=>{
try {
     const res=await axios.post("/profile/save",values)
      if(res.status===200){
        if(lastStep){
            setIsDialogOpen(true)
          }else{
            onNext()
          }
        
      }
     return res.data.wedding
} catch (error) {

     return rejectWithValue(error.response.data.message) 
}
})

export const getProfile=createAsyncThunk("profile/details",async(data,{rejectWithValue})=>{
  try {
    const res=await axios.get("/profile/details")
    return res.data.wedding
  } catch (error) {

     return rejectWithValue(error.response.data.message) 
  }
})

export const uploadBanner=createAsyncThunk("banner/upload",async({formdata,fieldName,handleCropper,setValues,values,setShowBackDrop,setStateSnackbarContext},{rejectWithValue})=>{
   try {
    const config={headers:{"Content-Type":"multipart/form-data"}}
    const res=await axios.post(`/profile/${fieldName}/upload`,formdata,config)
    setShowBackDrop(false)
    setValues({
        ...values,
        [fieldName]:{
            url:res.data.wedding[fieldName].url,
            public_id:res.data.wedding[fieldName].public_id
        }
    })
    handleCropper()
    setStateSnackbarContext(
        true,
        `${fieldName}  upload success`,
        "success"
      )
    return res.data.message
   } catch (error) {

    return rejectWithValue(error.response.data.message) 
   }
})


export const getProfileDetail=createAsyncThunk("profile/detail/id",async({id,navigate,setStateSnackbarContext,setNotFound},{rejectWithValue})=>{
try {
    const res=await axios.get(`/profile/details/${id}`)
     return res.data.wedding
} catch (error) {

    if(error.response.data.message==="Please Sign up first"){
        navigate(`/signup/${id}`)
        setStateSnackbarContext(
            true,
            "Please Signup",
            "warning"
        )
    }
   
    return rejectWithValue(error.response.data.message) 
}
})


export const uploadAlbum=createAsyncThunk("album/upload",async({formdata,setShowBackDrop,handleCropper,setStateSnackbarContext,setValues,values},{rejectWithValue})=>{
    try {
        const config={headers:{"Content-Type":"multipart/form-data"}}
        const res=await axios.post(`/profile/upload/album`,formdata,config)
        setShowBackDrop(false)
        setValues({
            ...values,
            footerImages:res.data.wedding.footerImages 
        })
        handleCropper()
        setStateSnackbarContext(
            true,
            `image  upload success`,
            "success"
          )
          return res.data.wedding
    } catch (error) {
        return rejectWithValue(error.response.data.message) 
    }
    })
    
    export const deleteAlbum=createAsyncThunk("banner/delete",async({id,handleClose},{rejectWithValue})=>{
        try {
         const res=await axios.post(`/profile/delete/album`,{id})
         handleClose()
         return res.data.wedding
        } catch (error) {
            return rejectWithValue(error.response.data.message)   
        }
    })
    
    export const reorderAlbum=createAsyncThunk("album/reorder",async({updatedImages},{rejectWithValue})=>{
        try {
             const res=await axios.post(`/profile/album/reorder`,updatedImages)
             return res.data.wedding
            } catch (error) {
            return rejectWithValue(error.response.data.message)   
         }
    })

const profileSlice=createSlice({
    name:"profile",
    initialState:{
        loading:false,
        weddingData:{},
        error:null,
        message:""
    },
    reducers:{
        clearError:(state)=>{
            state.error=null;
         }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(saveProfile.pending,(state)=>{
            state.loading=true
        })
        .addCase(saveProfile.fulfilled,(state,action)=>{
            state.loading=false;
            state.weddingData=action.payload
        })
        .addCase(saveProfile.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })

        .addCase(getProfile.pending,(state)=>{
            state.loading=true
        })
        .addCase(getProfile.fulfilled,(state,action)=>{
            state.loading=false;
            state.weddingData=action.payload
        })
        .addCase(getProfile.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })

        .addCase(uploadBanner.pending,(state)=>{
            state.loading=true
        })
        .addCase(uploadBanner.fulfilled,(state,action)=>{
            state.loading=false;
            state.message=action.payload
        })
        .addCase(uploadBanner.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })

        .addCase(getProfileDetail.pending,(state)=>{
            state.loading=true
        })
        .addCase(getProfileDetail.fulfilled,(state,action)=>{
            state.loading=false;
            state.weddingData=action.payload
        })
        .addCase(getProfileDetail.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })
        .addCase(uploadAlbum.pending,(state)=>{
            // state.loading=true
        })
        .addCase(uploadAlbum.fulfilled,(state,action)=>{
            state.loading=false;
            state.weddingData=action.payload
        })
        .addCase(uploadAlbum.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })
        .addCase(deleteAlbum.pending,(state)=>{
            // state.loading=true
        })
        .addCase(deleteAlbum.fulfilled,(state,action)=>{
            state.loading=false;
            state.weddingData=action.payload
        })
        .addCase(deleteAlbum.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })

        .addCase(reorderAlbum.pending,(state)=>{
            // state.loading=true
        })
        .addCase(reorderAlbum.fulfilled,(state,action)=>{
            state.loading=false;
            state.weddingData=action.payload
        })
        .addCase(reorderAlbum.rejected,(state,action)=>{
            state.loading=false;
            state.error=action.payload
        })
    }
})

export const {clearError}=profileSlice.actions

export default profileSlice.reducer