import React,{useState,useEffect, useRef} from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import inputErrors from '../../helpers/validation'
import {Container,Row} from 'react-bootstrap'
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import './event.css'
import dayjs from 'dayjs';
import {useDispatch, useSelector} from 'react-redux'
import { createEvent,clearError } from '../../../redux/reducers/eventSlice';
import {useNavigate} from 'react-router-dom'
import {snackbarContext} from '../../helpers/Snakbar'
import MapInput from './MapInput';
import { clearUser } from '../../../redux/reducers/authSlice';
import Navbar from '../../helpers/Navbar';
import { TabTitle } from '../../helpers/GeneralFunctions';
import Loader from '../../helpers/Loader';
import Footer from '../../helpers/Footer';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfoIcon from '@mui/icons-material/Info';
const CreateEvent = () => {
  const formRef = useRef(null);
  TabTitle("Create Event")
  const setStateSnackbarContext=React.useContext(snackbarContext)
  const {error:backendError,loading}=useSelector((state)=>state.event)
  const {hasEnded,userCode, groupSettings}=useSelector((state)=>state.auth.user)
  const navigate=useNavigate()
  const dispatch=useDispatch()
   const [values,setValues]=useState({
    eventName:"",
    eventDate:getCurrentDate(),
    eventTime:"",
    eventTheme:"",
    eventInfo:"",
    eventAddress:"",
    gLocation:{
      latitude:"",
      longitude:"",
      formatted_address:""
    },
    group: []
   })
      //  group: groupSettings.enabled ? "g1160a" : ""
  const [time, setTime] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

//   const handleChange=(event)=>{
  
//     setValues({
//         ...values,
//         [event.target.name]: event.target.value
//       });    
// }
const handleChange = (event) => {
  const { name, value, type, checked } = event.target;
  if (type === "checkbox") {
    // Handle group checkboxes
    setValues((prevValues) => {
      const updatedGroups = checked
        ? [...prevValues.group, value] // Add group to array
        : prevValues.group.filter((group) => group !== value); // Remove group from array

      return {
        ...prevValues,
        group: updatedGroups,
      };
    });
  } else {
    setValues({
      ...values,
      [name]: value
    });
  }
}
 const handleTimeChange=(newTime)=>{
  const hours = dayjs(newTime).format('hh:mm A');
  setValues(() => ({
    ...values,
    eventTime:hours
 }));
 }

 const addEvent=()=>{
  setSubmitting(false)
  dispatch(createEvent({values,navigate,setStateSnackbarContext}))
 }


useEffect(()=>{

if(isSubmitting){
  const noErrors = Object.keys(errors).length === 0;   
  if(noErrors){
    addEvent()
  }else{
    formRef.current.scrollIntoView({ behavior: "smooth" });
}
}

if (backendError === "session expired") {
  setStateSnackbarContext(true, "session expired please login again", "error");
  dispatch(clearError());
  dispatch(clearUser())
  navigate("/login")
} else if (backendError) {
  setStateSnackbarContext(true, backendError, "error");
  dispatch(clearError());
}



},[isSubmitting,errors,backendError])



if(hasEnded){
  window.location.href = "/display/notfound";
}


const handleSubmit=(e)=>{
  e.preventDefault()
  const validationErrors=inputErrors(values)
   setErrors(validationErrors)
  setSubmitting(true)
}

const groupMappings = {
  'a': 'g1160a',
  'b': 'g2330b',
  'c': 'g3140c',
  'd': 'g5210d'
};

// const generateGroupOptions = (numGroups) => {
//   const options = [];
//   for (let i = 0; i < numGroups; i++) {
//     const groupLetter = String.fromCharCode(65 + i).toLowerCase();
//     const encodedValue = groupMappings[groupLetter];
//     options.push(
//       <option key={i} value={encodedValue}>
//         {`Group ${groupLetter.toUpperCase()}`}
//       </option>
//     );
//   }
//   return options;
// };

const generateGroupOptions = (numGroups) => {
  const options = [];
  for (let i = 0; i < numGroups; i++) {
    const groupLetter = String.fromCharCode(65 + i).toLowerCase();
    const encodedValue = groupMappings[groupLetter];
    options.push(
      <div key={i} className='single-checkbox-div'>
        <input
          type="checkbox"
          value={encodedValue}
          checked={values.group.includes(encodedValue)}
          onChange={handleChange}
        />
        <label>{`Group ${groupLetter.toUpperCase()}`}</label>
      </div>
    );
  }
  return options;
}

if(loading){
  return <Loader/>
}
  return (
    <>
    <Navbar/>
    <Container>
    <Row  className='justify-content-center px-2'>
    <div className="col-lg-8 col-md-11 event-container  ">
    <h3 className='event-form-heading'>Add New Event</h3>
    
    <form onSubmit={handleSubmit} className='event-form' ref={formRef}>
    <div className={errors.eventName?"input-div-error":"input-div"}  >
    <input type="text"
     name="eventName" 
     value={values.eventName}
     onChange={handleChange}
     placeholder=' ' 
     className='input-field' />
    <label>Event name <span style={{color:"red"}}> * </span>  <span className='label-hide'>[ e.g. Reception,Mehendi ]  </span></label>
    </div>
    <div className='mb-3'>  {errors.eventName && (<small className="error-msg">{errors.eventName}</small>)} </div>
 
     <div className='row align-items-center'>
     
     <div className="col-sm-6">
      <input
        type="date"
        id="datePicker"
        name='eventDate'
        value={values.eventDate}
        onChange={handleChange}
        min={getCurrentDate()} // Set the min attribute to the current date
        required
        className='date-input'
      />
      </div>
     <div className='col-sm-6'>
    <LocalizationProvider dateAdapter={AdapterDayjs}  >
      <DemoContainer components={['TimePicker']}  className="time-container">
        <TimePicker
         label="Event time" 
         className='time-input' 
         name={errors.eventTime?"time-input-error":"time-input"}
         value={time} 
         onChange={handleTimeChange}
         required
         />
      </DemoContainer>
    </LocalizationProvider> 
    <div className='mb-3'>  {errors.eventTime && (<small className="error-msg">{errors.eventTime}</small>)} </div>
    </div>
  
    </div>
     <div className={errors.eventTheme?"input-div-error":"input-div"} >
     <input type="text" 
      placeholder=' ' 
      name="eventTheme" 
      value={values.eventTheme}
      onChange={handleChange}
      className='input-field' />
     <label>Theme  <span className='label-hide'>[ e.g. Boys-Black Kurta Girls-Pink Saree ]</span></label>     
     </div>
     <div className='mb-3'>  {errors.eventTheme && (<small className="error-msg">{errors.eventTheme}</small>)} </div>
     <div  className={errors.eventAddress?"input-div-error":"input-div"}>
     <input type="adress" 
     className='input-field' 
     name="eventAddress" 
     value={values.eventAddress}
     onChange={handleChange}
       placeholder=' ' />
     <label>Address<span className='label-hide'>[ e.g. Shagun Palace ]</span></label>     
     </div>
     <div className='mb-3'>  {errors.eventAddress && (<small className="error-msg">{errors.eventAddress}</small>)} </div>
     <textarea
      name="eventInfo" 
      className={errors.eventInfo?"textarea-error":"info-textarea"}
      value={values.eventInfo}
       cols="30" 
       rows="3" 
       onChange={handleChange}
       placeholder='  Addtional Event Information'></textarea>
       <div className='mb-3'>  {errors.eventInfo && (<small className="error-msg">{errors.eventInfo}</small>)} </div>
       <div>
       <small style={{ display: "inline-block", marginBottom: "10px" }}><b>Google Map Location:</b>
                  <OverlayTrigger placement="auto" overlay={
                    <Tooltip id="tooltip-gmaps"> <span style={{ textAlign: "justify", }}> Search or Enter Event Location for Google Maps Link <br />  (<GpsFixedIcon style={{ fontSize: "16px" }} /> For Fetching Current Location)</span></Tooltip>
                  }>
                    <InfoIcon className='tooltip-icon' />
                  </OverlayTrigger>
                </small> 
                <MapInput setValues={setValues} values={values} />
       </div>
       {/* {groupSettings.enabled && (
                <div className={errors.group ? 'input-div-error' : 'input-div'} style={{ paddingTop: "15px" }}>
                  <small style={{ display: "inline-block", marginBottom: "10px", color: "blue" }}><b>Group [special feature]</b>
                    <OverlayTrigger placement="auto" overlay={
                      <Tooltip id="tooltip-group">
                        <span>
                          Group multiple events into one invite.<br />
                          Example Group A: [Event A, Event B]<br />
                          Example Group B: [Event A]
                        </span>
                      </Tooltip>
                    }>
                      <InfoIcon className='tooltip-icon' />
                    </OverlayTrigger>
                  </small>
                  <select
                    name="group"
                    value={values.group}
                    onChange={handleChange}
                    className="input-field"
                  >
                    {generateGroupOptions(groupSettings.numberOfGroups)}
                  </select>
                </div>
              )} */}
              {groupSettings.enabled &&(
                <>
                <small className='group-small'><b>Group [special feature]</b>
                    <OverlayTrigger placement="auto" overlay={
                      <Tooltip id="tooltip-group">
                        <span>
                          Group multiple events into one invite.<br />
                          Example Group A: [Event A, Event B]<br />
                          Example Group B: [Event A]
                        </span>
                      </Tooltip>
                    }>
                      <InfoIcon className='tooltip-icon' />
                    </OverlayTrigger>
                  </small>
              <div className='checkbox-div'>
                {generateGroupOptions(groupSettings.numberOfGroups)}
              </div>
              </>
              )} 
     <button className='event-submit-button' type='submit'>Submit</button>
     </form>
     </div>
     </Row>
     </Container>
     <Footer userCode={userCode}/>
    </>
  )
}

export default CreateEvent