import React,{useState} from 'react'
import './cropper.css'
import CancelIcon from '@mui/icons-material/Cancel';
import {snackbarContext} from '../../helpers/Snakbar'
import Cropper from 'react-easy-crop'
import getCroppedImg from "../../helpers/cropImage";
import { useDispatch } from 'react-redux';
import { uploadAlbum,uploadBanner } from '../../../redux/reducers/profileSlice';
import {dataURLtoFile} from '../../helpers/dataURLtoFile'
import BackDrop from '../../helpers/BackDrop';
const CropperCom = ({handleCropper,setValues,values,imageType}) => {
    const inputRef =React.useRef()
    const [image,setImage]=useState(null)
    const [crop,setCrop]=useState({x:0,y:0})
    const [zoom,setZoom]=useState(1)
    const [cropArea,setcropArea]=useState(null)
    const [showBackDrop,setShowBackDrop]=useState(false)
    const setStateSnackbarContext=React.useContext(snackbarContext)
     const dispatch=useDispatch()
    // const onSelectFile=(event)=>{
    
    //     if (event.target.files[0].size === undefined) {
    //       return;
    //     }
          
    //       if(event.target.files[0].size > 5000000){
    //         event.target.value=""
    //         return setStateSnackbarContext(
    //           true,
    //           "file size to big [less than 5MB]",
    //           "warning"
    //         )
    //       } else{
    //         if(event.target.files[0].type==="image/jpeg" ||event.target.files[0].type==="image/png" ||event.target.files[0].type==="image/webp" || event.target.files[0].type==="image/heif" ){
    //           const reader=new FileReader()
    //           reader.readAsDataURL(event.target.files[0])
    //           reader.addEventListener('load',()=>{
    //             setImage(reader.result)
    //           })
    //         }else{
    //           event.target.value=""
    //           return setStateSnackbarContext(
    //             true,
    //             "invalid file type",
    //             "error"
    //           )
    //         }
    //         event.target.value=""
    //       }
    //     }

    const onSelectFile = (event) => {
      const allowedExtensions = ['.jpeg', '.jpg', '.png', '.webp', '.heif', '.heic'];
      const file = event.target.files[0];
    
      if (!file) {
          return setStateSnackbarContext(true, "Please select an image", "warning");
      }
    
      const fileExtension = file.name.split('.').pop().toLowerCase();
    
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
          event.target.value = "";
          return setStateSnackbarContext(true, "Invalid file type", "error");
      }
    
      if (file.size > 5000000) {
          event.target.value = "";
          return setStateSnackbarContext(true, "File size too big (less than 5MB)", "warning");
      }
    
      const reader = new FileReader();
      reader.readAsDataURL(file);
    
      reader.addEventListener('load', () => {
          setImage(reader.result);
      });
    
      event.target.value = "";
    };
        const onCropComplete=(croppedAreaPercentage,croppedAreaPixels)=>{
            setcropArea(croppedAreaPixels)
          }

          const handleScale = (e) => {
            const scale = parseFloat(e.target.value);
            setZoom(scale);
          };

          const triggerFilePopup=()=>inputRef.current.click()

          const onClear=()=>{
         
            setImage(null)
            if(!image)
            return setStateSnackbarContext(
              true,
              "Please select an image",
              "warning"
            )
          }
          const onUpload=async()=>{
            if(!image)
            return setStateSnackbarContext(
              true,
              "Please select an image",
              "warning"
            )
            setShowBackDrop(true)
           
             const canvas=await getCroppedImg(image,cropArea)
             const canvasDataUrl=canvas.toDataURL("image/jpeg")
             const connvertedDataUrl=dataURLtoFile(canvasDataUrl,'cropped-image.jpeg')
             const fieldName=imageType
             const formdata=new FormData()
              formdata.append(fieldName,connvertedDataUrl)
              if(imageType==="footerImage"){
                   dispatch(uploadAlbum({formdata,setShowBackDrop,handleCropper,setStateSnackbarContext,setValues,values}))
              }else{
                dispatch(uploadBanner({formdata,fieldName,handleCropper,setValues,values,setShowBackDrop,setStateSnackbarContext}));
              }
           
          }

        
    return (
      <>
    <div className='default-bg-conatiner'>
              <CancelIcon  className='cancel-icon' onClick={handleCropper} />
              <input type="file"  ref={inputRef} style={{display:"none"}} onChange={onSelectFile}  />
              <div className="container-cropper">
           {
            image?<>
            <div className="cropper">
    <Cropper 
        image={image} 
        crop={crop} 
        zoom={zoom}  
        aspect={imageType === "bannerImage" ? 2 / 3 : imageType === "footerImage" ? 3 / 2 : 1} 
        onCropChange={setCrop} 
        onZoomChange={setZoom} 
        onCropComplete={onCropComplete}  
    />
</div>
            <div className="slider"><span>Zoom:</span><input type="range"  min={1}  max={3} step={0.1} value={zoom} onChange={handleScale} />  </div>
            </>:null
           }
              </div>
              <div className="container-button">
           
           <button className="btn btn-primary options-buttons"   style={{marginRight:"5px"}} onClick={triggerFilePopup}>CHOOSE</button>
           <button className="btn btn-secondary options-buttons"   style={{marginRight:"5px"}} onClick={onUpload} >UPLOAD</button>
           <button className="btn btn-danger options-buttons" onClick={onClear}  >CLEAR</button>
    
      </div>
    </div>
    {showBackDrop && <BackDrop/>}
    </>
  )
}

export default CropperCom
