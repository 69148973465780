import React from 'react'
import Slider from "react-slick";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FilterVintageIcon from '@mui/icons-material/FilterVintage';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GoogleMapDiv from './GoogleMapDiv'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import './slider.css'
import dayjs from 'dayjs';
const EventSlider = ({ events }) => {

  const CustomPrevArrow = (props) => (
    <div onClick={props.onClick} className="custom-prev-arrow">
      <ArrowBackIosNewOutlinedIcon />
    </div>
  );

  const CustomNextArrow = (props) => (
    <div onClick={props.onClick} className="custom-prev-arrow">
      <ArrowForwardIosOutlinedIcon />
    </div>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: true,
    prevArrow: <CustomPrevArrow />, // Custom component for the previous arrow
    nextArrow: <CustomNextArrow />, // Custom component for the next arrow
    responsive: [
      {
        breakpoint: 425,
        settings: {
          arrows: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(events.length, 1),
          slidesToScroll: 1,

        },
      },
      {
        breakpoint: 1130,
        settings: {
          slidesToShow: Math.min(events.length, 2),
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: Math.min(events.length, 3),
          slidesToScroll: 1,
        },
      },
    ],
  };

  const formatDate = (inputDate) => {
    const date = dayjs(inputDate);
    const formattedDate = date.format('DD MMMM YYYY');
    return formattedDate
  };

  return (

    <div className="wedding-carousel" id='events-section'  data-aos="fade-up">

      {events && (events.length > 0) ? <h1 className='main-heading'>Bless us with your presence</h1> : null}
      {events && (events.length > 0) ?
        <Slider {...settings}>
          {events.map((event, i) => (

            <div className="event-card-final" key={i}>
              <h1>{event.eventName}</h1>
              <div className="event-sepration"> <FilterVintageIcon className='event-sepration-icon' /></div>
              <div className="event-card-final-date">
                <h2>{formatDate(event.eventDate)}</h2>
              </div>
              <div className="event-card-time">
                <span>{event.eventTime}</span>
              </div>
              <div className="event-card-address">
                <span>{event.eventAddress ? `@ ${event.eventAddress}` : ""}</span>
              </div>
              {event.gLocation && event.gLocation.latitude &&
                <div className="event-address">
                  <GoogleMapDiv latitude={event.gLocation.latitude} longitude={event.gLocation.longitude} />
                </div>}
              {event.eventTheme || event.eventInfo ? (
                <>
                  <div className="additional-div">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Addtional Information</Typography>
                      </AccordionSummary>
                      {event.eventTheme && (<AccordionDetails>
                        <Typography style={{ wordWrap: "break-word" }}>
                          <b>Theme:</b> {event.eventTheme}
                        </Typography>
                      </AccordionDetails>)}
                      {event.eventInfo && (<AccordionDetails>
                        <Typography>
                          <b>Event Info:</b> {event.eventInfo}
                        </Typography>
                      </AccordionDetails>)}

                    </Accordion>
                  </div>
                </>
              ) : null}


            </div>
          ))}

        </Slider> : <h1 className='main-heading'>No Events Created</h1>}

    </div>
  )
}

export default EventSlider