import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarContext } from '../../helpers/Snakbar';
import CropperCom from './CropperCom';
import { deleteAlbum, reorderAlbum } from '../../../redux/reducers/profileSlice';
import './album.css';

const ImageAlbum = ({ handleAlbum }) => {
  const setStateSnackbarContext = React.useContext(snackbarContext);
  const [showCropper, setShowCropper] = useState(false);
  const [open, setOpen] = useState(false);
  const [imageId, setImageId] = useState();
  const { weddingData: profileData, loading } = useSelector((state) => state.profile);
  const [values, setValues] = useState({
    footerImages: [],
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setValues({
      ...values,
      footerImages: profileData.footerImages,
    });
  }, [profileData]);

  const handleCropper = () => setShowCropper((prevValue) => !prevValue);

  const handleClickOpen = (id) => {
    setImageId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setImageId(null);
  };

  const deleteFunc = (id) => {
    dispatch(deleteAlbum({ id, handleClose }));
  };

  const handleAlert = () => {
    setStateSnackbarContext(true, `Maximum of 4 images allowed`, 'warning');
  };

  // Handle drag and drop
  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination || destination.index === source.index) return;

    const updatedImages = [...values.footerImages];
    const [movedImage] = updatedImages.splice(source.index, 1);
    updatedImages.splice(destination.index, 0, movedImage);

    setValues({
      ...values,
      footerImages: updatedImages,
    });

    dispatch(reorderAlbum({ updatedImages }));
  };

  return (
    <>
      <div className="album-container">
        <CancelIcon className="cancel-icon" onClick={handleAlbum} />
        <h2 className="album-header">Photo Slider</h2>
        <p className='slider-description'>Create a slider with 1-4 images (landscape preferred)</p>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="images-container">
            {(provided) => (
              <div
                className="images-container"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {values.footerImages.length > 0 ? (
                  values.footerImages.map((image, index) => (
                    <Draggable key={image.public_id} draggableId={image.public_id} index={index}>
                      {(provided) => (
                        <div
                          className="image-wrapper"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          {/* Drag handle */}
                          <div
                            className="drag-handle-wrapper"
                            {...provided.dragHandleProps} // Attach dragHandleProps to the wrapper div
                          >
                            <DragHandleIcon className="dragabble-handle" />
                          </div>

                          {/* Delete button */}
                          <DeleteIcon
                            className="delete-icon"
                            onClick={() => {
                              // e.stopPropagation(); // Prevent drag from triggering
                              handleClickOpen(image.public_id);
                            }}
                          />

                          {/* Image */}
                          <img
                            src={image.url}
                            alt={`Uploaded ${index}`}
                            className="uploaded-image"
                            srcSet={`
                              ${image.url.replace('/upload/', '/upload/w_320/')} 320w,
                              ${image.url.replace('/upload/', '/upload/w_640/')} 640w,
                              ${image.url.replace('/upload/', '/upload/w_1080/')} 1080w
                            `}
                            sizes="(max-width: 320px) 320px, 
                                   (max-width: 640px) 640px, 
                                   (max-width: 1080px) 1080px"
                          />
                        </div>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <p className="no-images">No images uploaded yet.</p>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {values.footerImages.length >= 4 ? (
          <button className="add-images-button upload-max" onClick={handleAlert}>
            Add Images
          </button>
        ) : (
          <button className="add-images-button" onClick={handleCropper}>
            Add Images
          </button>
        )}
      </div>
      {showCropper && (
        <CropperCom
          handleCropper={handleCropper}
          setValues={setValues}
          values={values}
          imageType="footerImage"
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you Sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Once deleted cannot be recovered back
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={() => deleteFunc(imageId)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImageAlbum;
